<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title> <h2>Cadastro de Sessões </h2></v-card-title>
    </v-card>
    <!-- EDITOR DA SESSÃO-->
    <v-row>
    
      <v-col class="text-end">
        <Add :current-item="{}" @atualiza="get_sessoes()"/>
      </v-col>
    </v-row>

    <!-- LISTA SESSÕES-->
    <v-row>
      <v-col>
        <!-- <v-text-field
          v-model="operadora_"
          outlined
          label="Operadora"
          append-icon="mdi-magnify"
        ></v-text-field> -->
      </v-col>
      <v-col>
        <!-- <v-text-field
          v-model="paciente_"
          outlined
          label="Paciente"
          append-icon="mdi-magnify"
        ></v-text-field> -->
      </v-col>
      <v-col>
        <v-text-field
          v-model="data_inicio_"
          outlined
          label="Data início"
          type="date"
        ></v-text-field>
      </v-col>
      <v-col>
      
        <v-text-field
          v-model="data_fim_"
          outlined
          label="Data fim"
          type="date"
        ></v-text-field>
      </v-col>
    
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :items="sessoes"
          :search="search"
          :headers="headers"
        >
          <!--pesquisa e atualiza lista-->

          <template v-slot:top>
            <v-row dense >
              <v-col cols="11">
                <v-text-field v-model="search" label="Nomes ou operadoras" class="mx-0"></v-text-field>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.equipe`]="{ item }">
            <v-chip class="ma-1" v-for="i in item.equipe" :key="i.id">
              {{ i.first_name }}
            </v-chip>
          </template>
          <template v-slot:[`item.data_inicio`]="{ item }">
            {{ item.data_inicio | formatDatabr }}
          </template>
          <template v-slot:[`item.data_fim`]="{ item }">
            {{ item.data_fim | formatDatabr }}
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <Add :current-item="item"/>
            <v-progress-circular
              v-show="false"
              :indeterminate="false"
              color="green"
            ></v-progress-circular>
          </template>
          <template
            v-slot:[`item.assinar`]="{ item }"
          >
            <AssinarSessao :sessao-current="item" />
          </template>
        </v-data-table>
      
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import api from "@/http";
// import { mapGetters } from 'vuex'
export default {
  name: "Sessoes",
  components: {
    Add: ()=>import('./componentes/Add.vue'),
    AssinarSessao: ()=>import('./componentes/AssinarSessao.vue')
  },
  data: () => ({
    search: "", // tipo string
    operadora_ : "", 
    paciente_ : "", 
    data_inicio_ : "", 
    data_fim_ : "", 
    items: [], // tipo array
    loading: false, // tipo boolean
    headers: [
      { text: "Id", align: "start", sortable: true, value: "id" },
      { text: "Convênio", align: "start", sortable: true, value: "paciente.operadora.nome"},
      { text: "Paciente", align: "end", sortable: true, value: "paciente.nome" },
      { text: "Equipe", align: "start", sortable: true, value: "equipe" },
      { text: "Procedimento", align: "end", sortable: true, value: "procedimento.nome"},
      { text: "Qtd. Liberada", align: "center", sortable: true, value: "qtd" },
      { text: "Qtd. Concluída", align: "center", sortable: true, value: "evolucao" },
      { text: "Frequência", align: "end", sortable: true,value: "frequencia.nome" },
      { text: "Data ínicio", align: "start", sortable: true, value: "data_inicio" },
      { text: "Data fim", align: "end", sortable: true, value: "data_fim" },
      { text: "Senha", align: "end", sortable: true, value: "senha" },
      { text: "Editar", align: "end", sortable: true, value: "edit" },
      { text: "Assinar", align: "end", sortable: true, value: "assinar" },
    ],
  }),
  methods: {
    get_sessoes() {
      this.loading = true;
      api("atendimentos/sessoes/")
        .then((response) => {
          this.items = response.data;
        })
        .catch(() => {
          alert("Erro no link ao solicitatr sessões");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.get_sessoes()
  },
  computed:{
    sessoes(){
      let a = this.items.filter((e)=>{
        return e.paciente.operadora.nome.toUpperCase().includes(
          this.operadora_.toUpperCase()
        )
      })

      //  let b = a.filter((e)=>{
      //    return e.paciente.nome.toUpperCase().includes(
      //      this.paciente_.toUpperCase()
      //    )
      //  })
      let c=a.filter((e)=>{
        return this.data_inicio_<= e.data_inicio&&this.data_fim_<=e.data_fim
        
      })
      
      if(c.length==0){
        // b = this.items
        c=this.items
        
      }
      return c 
    }
  }
};
</script>
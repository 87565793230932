var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('h2',[_vm._v("Cadastro de Sessões ")])])],1),_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('Add',{attrs:{"current-item":{}},on:{"atualiza":function($event){return _vm.get_sessoes()}}})],1)],1),_c('v-row',[_c('v-col'),_c('v-col'),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","label":"Data início","type":"date"},model:{value:(_vm.data_inicio_),callback:function ($$v) {_vm.data_inicio_=$$v},expression:"data_inicio_"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","label":"Data fim","type":"date"},model:{value:(_vm.data_fim_),callback:function ($$v) {_vm.data_fim_=$$v},expression:"data_fim_"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.sessoes,"search":_vm.search,"headers":_vm.headers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{staticClass:"mx-0",attrs:{"label":"Nomes ou operadoras"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.equipe",fn:function(ref){
var item = ref.item;
return _vm._l((item.equipe),function(i){return _c('v-chip',{key:i.id,staticClass:"ma-1"},[_vm._v(" "+_vm._s(i.first_name)+" ")])})}},{key:"item.data_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatabr")(item.data_inicio))+" ")]}},{key:"item.data_fim",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatabr")(item.data_fim))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('Add',{attrs:{"current-item":item}}),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"indeterminate":false,"color":"green"}})]}},{key:"item.assinar",fn:function(ref){
var item = ref.item;
return [_c('AssinarSessao',{attrs:{"sessao-current":item}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }